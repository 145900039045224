// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  basehref: '/',
  domain: '',
  authServerUrl: 'http://localhost:8085/session/token',
  authServerUrlValid: 'http://localhost:8085/session',
  //#region custo
  resetPasswordRequestUrl: 'ws/user/password',
  //#endregion custo
  configFilePath: '/iupics-config/default/formation',
  clientId: 'ws_api_001',
  clientSecret: 'ws_api_secret',
  production: true,
  //#region custo
  appName: 'SCRIBe',
  CUST_version: 'in development mode',
  STD_version: '2.4.1',
  webSiteUrl: 'https://www.apizsolutions.com/',
  isWIP: false
  //version: 'Scribe {version}'
  //#endregion custo
};
